import MyNavbar from './MyNavbar'
import '../styles/Header.css'

const Header = () => {
    return (<>
        {/* <div className="logo">
            <img src='/logo-black.png' alt='logo' />
        </div> */}
        <div className="let-me-test">
            <MyNavbar />
        </div>
    </>);
};

export default Header;