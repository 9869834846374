import '../styles/AboutUs.css'
import BackgroundImg from '../components/BackgroudImg'
import backgroundImagePath from './Menu header.png'

const Book = () => {
    return (
        <>
            {/* <video autoPlay muted loop >
                <source type="video/mp4" src="/video-final.mp4" />
            </video> */}
            <BackgroundImg imgUrl={backgroundImagePath} name='Book bord' />

            <div className="about-body">
                {/* <img src="/Group 2.png" alt="about us"></img> */}
                <div className="about-contents">

                    <div >
                        <i className="fa fa-volume-control-phone" aria-hidden="true"></i>
                        &nbsp; &nbsp; Ring til os
                    </div>
                    <div>+45 33338988</div>
                    <div> &nbsp;</div>
                    {/* <div > <i className="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;
                        Skriv til os
                    </div>
                    <div className="email">Email: kujakusushi@hotmail.com</div> */}
                </div>

            </div>
            <img className="test" src='./Jtext.png' alt='imagei'></img>
        </>

    );
};

export default Book;