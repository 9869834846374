import { useEffect, useState } from 'react';
import BackgroundImg from '../components/BackgroudImg';
import '../styles/AboutUs.css'
const AboutUs = () => {

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [windowSize, setWindowSize] = useState((getWindowSize()));

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            {windowSize.innerWidth > 600 ? <video autoPlay muted loop >
                <source type="video/mp4" src={windowSize.innerWidth > 600 ? '/video notext.mp4' : '/phone video.mp4'} />
            </video> :
                <BackgroundImg name="Om os" />
            }


            <div className="about-logo"><img src='/logo-black.png' alt='logo' />
            </div>
            <div className="about-body">
                <div className="about-contents">
                    <p className="extra-contents">Åbningstider</p>
                    <p>mandag-søndag 14:00-22:00</p>

                </div>

            </div>
            <div>
                <div className="about-us-text">
                    <div className='title'>sushi ad libitum i 2 timer</div>
                    <div> mandag-torsdag 279kr </div>
                    <div>fredag-søndag 299kr</div>
                    <div>helligdage 299kr</div>
                    <div className='sub'>(Børn under 11år :149kr)</div>
                    <div>Fri bar i 2 timer +200kr</div>
                    <div className='sub'>(incl. sodavand, fadøl, rød og hvidvin)</div>
                </div>
                <img className="test" src='./Om os-image02.png' alt='imagei'></img>
            </div>
            <img className="test" src='./Jtext.png' alt='imagei'></img>
        </>

    );
};

export default AboutUs;