import '../styles/AboutUs.css'
import '../styles/TakeAway.css'
import BackgroundImg from '../components/BackgroudImg'
import backgroundImagePath from './Menu header.png'


const TakeAway = () => {
    const menus = [[
        {
            name: "laks menu",
            price: "179 DKK",
            img: "/menus/takeaway/Laks-Menu.jpg"
        }, {
            name: "Tun menu",
            price: "189 DKK",
            img: "/menus/takeaway/Tun menu.jpg"

        }, {
            name: "Nigiri menu",
            price: "159 DKK",
            img: "/menus/takeaway/Nigirimenu.jpg"
        }
    ], [{
        name: "Menu 1(34 stk)",
        price: "229 DKK",
        img: "/menus/takeaway/Menu1.jpg"
    }, {
        name: "Menu 2(32 stk)",
        price: "299 DKK",
        img: "/menus/takeaway/Menu2.jpg"
    }, {
        name: "Menu 3(24 stk)",
        price: "359 DKK",
        img: "/menus/takeaway/Menu3.jpg"
    }], [{
        name: "Menu4(56 stk)",
        price: "489 DKK",
        img: "/menus/takeaway/Menu4.jpg"
    }, {
        name: "Menu5(64 stk)",
        price: "699 DKK",
        img: "/menus/takeaway/Menu5.jpg"
    }, {
        name: "Menu6(12 stk)(V)",
        price: "165 DKK",
        img: "/menus/takeaway/Menu6.jpg"
    },]]

    return (
        <>
            <BackgroundImg imgUrl={backgroundImagePath} name='Take away' />
            {menus.map((menuItems) => {
                return (
                    <div className="menu-items">
                        {menuItems.map((menu) => {
                            console.log(menu.img)

                            return (
                                <div className='menu-item' >
                                    <img src={menu.img} alt={menu.name} />
                                    <div className='menu-name'>
                                        {menu.name}
                                    </div>
                                    <div className='menu-price'>
                                        {menu.price}
                                    </div>
                                </div>)
                        })}
                    </div>
                )

            })
            }
        </>

    );
};

export default TakeAway;