import '../styles/Footer.css'

const Footer = () => {
    return (<div className="footer">
        <div className="footer-logo">
            <img src='/logo-white.png' alt='logo' />
        </div>
        <div className="footer-intro">
            {/* <div className="footer-title">
                Kujaku sushi
            </div> */}
            <div className="footer-contents">
                Address: Nørregade 28, 1165 København K<br />
                Phone: +45 33338988<br />
                Email: kujakusushi@hotmail.com<br />
                Find us on Facebook <a href="https://m.facebook.com/kujakusushiKB">
                    <img src='/facebook.png' alt='logo' />
                </a> kujaku nørregade
            </div>
        </div>
    </div>);
};

export default Footer;