import './App.css';
// import MyNavbar from './components/MyNavbar'
// import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import Menu from "./pages/Menu";
import AboutUs from "./pages/AboutUs";
import Book from "./pages/Book";
import TakeAway from './pages/TakeAway';
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/menu" element={<Menu />} />
        <Route path="/" element={<AboutUs />} />
        <Route path="/book" element={<Book />} />
        <Route path="/takeaway" element={<TakeAway />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
  // return (
  //   <div className="App">
  //     <h1>Welcome to React Router!</h1>
  //     <Routes>
  //       <Route path="/" element={<Menu />} />
  //       <Route path="about" element={<AboutUs />} />
  //     </Routes>
  //   </div>
  // );
}

export default App;
