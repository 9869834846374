import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../styles/MyNavbar.css';

const MyNavbar = () => {
    const [open, setOpen] = useState(true);
    return (
        <Navbar className="w-100" bg="none" variant="dark" expand="sm">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => {
                    setOpen(!open)
                }} />
                <Navbar.Collapse id="basic-navbar-nav" in={open}>
                    <Nav className="navbar-nav nav-fill w-100 pt-1">
                        <Nav.Link href="/menu"><div className='underline'><span>Menu</span></div></Nav.Link>
                        <Nav.Link href="/"><div className='underline' ><span>Om os</span></div></Nav.Link>
                        <Nav.Link className="logo" href="/">
                            <img src='/logo-white.png' alt='logo' />
                        </Nav.Link>
                        <Nav.Link href="/book"><div className='underline'><span>Book bord</span></div></Nav.Link>
                        <Nav.Link href="/takeaway"><div className='underline' ><span>Take away</span></div></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default MyNavbar;