import { Fragment } from 'react';
import '../styles/BackgroundImage.css'
import Arrow from './arrow.gif'

const BackgroundImg = (props) => {

    return (
        <Fragment>
            <div
                className="header-background-image"
            ></div>
            <div className='arrow'><img src={Arrow} alt='arrow'></img></div>

            <div className="name-bottom">&loz; &nbsp; &nbsp;{props.name} &nbsp; &loz;</div>
        </Fragment>
    )

}

export default BackgroundImg;