import '../styles/Menu.css'
import Carousel from 'react-bootstrap/Carousel';
import BackgroundImg from '../components/BackgroudImg'
import backgroundImagePath from './Menu header.png'

const menus = [
    {
        name: "01",
        img: "/menus/menus/01.png"
    }, {
        name: "02",
        img: "/menus/menus/02.png"
    }, {
        name: "03",
        img: "/menus/menus/03.png"
    }, {
        name: "04",
        img: "/menus/menus/04.png"
    }, {
        name: "05",
        img: "/menus/menus/05.png"
    }, {
        name: "06",
        img: "/menus/menus/06.png"
    }, {
        name: "07",
        img: "/menus/menus/07.png"
    }, {
        name: "08",
        img: "/menus/menus/08.png"
    }, {
        name: "09",
        img: "/menus/menus/09.png"
    }, {
        name: "10",
        img: "/menus/menus/13.png"
    }, {
        name: "11",
        img: "/menus/menus/11.png"
    }, {
        name: "12",
        img: "/menus/menus/12.png"
    }, {
        name: "13",
        img: "/menus/menus/13.png"
    }, {
        name: "14",
        img: "/menus/menus/14.png"
    }, {
        name: "15",
        img: "/menus/menus/15.png"
    }, {
        name: "16",
        img: "/menus/menus/16.png"
    }, {
        name: "17",
        img: "/menus/menus/17.png"
    }, {
        name: "18",
        img: "/menus/menus/18.png"
    }, {
        name: "19",
        img: "/menus/menus/19.png"
    }, {
        name: "20",
        img: "/menus/menus/20.png"
    }, {
        name: "21",
        img: "/menus/menus/21.png"
    }, {
        name: "22",
        img: "/menus/menus/22.png"
    }, {
        name: "23",
        img: "/menus/menus/23.png"
    }
]

const Menu = () => {
    return (<div className="menu">
        <BackgroundImg imgUrl={backgroundImagePath} name='Menu' />
        <Carousel>

            {menus.map(menu => (
                <Carousel.Item className="carousel-sth">
                    <img
                        className="d-block"
                        src={menu.img}
                        alt={menu.name}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    </div >);
};

export default Menu;